import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//Styled components imports
import Wrapper from "./Wrapper"
import SectionWrapper from "./SectionWrapper"
import Title from "./Title"
import List from "./List"
import ListItem from "./ListItem"
import Link from "./Link"

const ForCustomers = () => {
  const intl = useIntl()

  return (
    <Wrapper>
      <SectionWrapper>
        <Title>
          {intl.formatMessage({
            id: "pages.for_customers.title",
          })}
        </Title>
        <List>
          <ListItem>
            <Link to="/pro-zakazniky/pece-o-drevo">
              {intl.formatMessage({
                id: "pages.for_customers.wood",
              })}
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/pro-zakazniky/pece-o-kuze">
              {intl.formatMessage({
                id: "pages.for_customers.leather",
              })}
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/pro-zakazniky/pece-o-provazy">
              {intl.formatMessage({
                id: "pages.for_customers.ropes",
              })}
            </Link>
          </ListItem>
          <ListItem>
            <Link to="/pro-zakazniky/pece-o-latex">
              {intl.formatMessage({
                id: "pages.for_customers.latex",
              })}
            </Link>
          </ListItem>
          {/* <ListItem>
            <Link to="/pro-zakazniky/pece-o-ocel">Ocel</Link>
          </ListItem> */}
        </List>
      </SectionWrapper>
      {/* <SectionWrapper>
        <Title>Techniky</Title>
        <List>
          <ListItem>
            <Link to="/">Epoxidová pryskyřice</Link>
          </ListItem>
          <ListItem>
            <Link to="/">Opalování dřeva</Link>
          </ListItem>
        </List>
      </SectionWrapper> */}
    </Wrapper>
  )
}

export default ForCustomers
