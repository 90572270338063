import styled from "styled-components"

const Title = styled.h3`
  font-size: 20px;
  text-align: center;
  margin-bottom: 25px;

  &:before {
    content: "• ";
  }

  &:after {
    content: " •";
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-bottom: ${props =>
      props.marginBottom ? props.marginBottom : "42px"};

    font-size: 36px;
  }
`

export default Title
