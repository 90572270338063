import React from "react"

//React components imports
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import ForCustomers from "../components/ForCustomers"

const ForCustomersPage = ({ location }) => {
  return (
    <Layout subtitle={"Pro zákazníky"}>
      <Breadcrumbs
        data={{
          path: location.pathname.replace("/en", ""),
          breadEnd: "Pro zákazníky",
        }}
      />
      <ForCustomers />
    </Layout>
  )
}

export default ForCustomersPage
