import styled from "styled-components"

const List = styled.ul`
  list-style-type: disc;
  font-size: 16px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 24px;
  }
`

export default List
