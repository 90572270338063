import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (${props => props.theme.mediaQueries.desktop}) {
    grid-template-columns: 1fr;
  }
`

export default Wrapper
